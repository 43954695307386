@mixin centerItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@mixin cardShadow {
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.2);
}

.app {
  // height: 100%;
  min-height: 1200px;
  width: 100%;
  // overflow-x: hidden;
  // overflow-y: hidden;
  #landing-page-container {
    header {
      position: relative;
      height: 150px;
      width: 100%;
      padding-top: 85px;
      text-align: center;
      background-color: #ce1011;
      color: #fff;
      letter-spacing: -0.5px;
      font-size: 40px;
      font-family: 'Dosis', sans-serif;
    }
  }

  .note {
    width: 85%;
    margin-top: 3px;
    text-align: left;
    font-size: 10px;
    color: #ce1011;
  }

  .user-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    right: 15px;
    top: 15px;
    @include cardShadow;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../icons/user-icon.svg');
    cursor: pointer;
    z-index: 2;
    .username {
      position: absolute;
      right: 55px;
      text-align: right;
      margin-top: 10px;
    }
  }

  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
  }

  h1,
  h2 {
    color: #ce1011;
    font-size: 48px;
    text-align: center;
    font-weight: 400;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 30px;
    font-weight: 400;
    max-width: 85%;
    padding-top: 5px;
    padding-bottom: 10px;
  }

  p {
    font-size: 14px;
  }

  .auth-container {
    @include centerItems;

    .disable-login {
      opacity: 0.4;
      pointer-events: none;
    }

    margin-top: 60px;
    #auth-card {
      @include centerItems;
      @include cardShadow;
      background-color: #fff;
      border-radius: 2px;
      height: auto;
      width: 380px;
      padding: 15px;
      padding-bottom: 30px;
      min-height: 340px;
      @media only screen and (max-width: 600px) {
        width: 290px;
      }

      h1 {
        margin-bottom: 8px;
      }
      h3 {
        text-align: center;
      }

      .input-text,
      .login-button {
        width: 85%;
        margin-top: 15px;
      }

      p.auth-note {
        margin-top: 25px;
        text-align: center;
        font-size: 14px;
      }
    }
    .short-card {
      min-height: 280px !important;
    }
  }

  .text-link {
    font-weight: 500;
    color: #ce1011;
    cursor: pointer;
  }

  .text-link:hover {
    text-decoration: underline;
  }

  #home-container,
  .compliance-container {
    position: relative;
    width: 95%;
    // max-width: 1400px;
    // min-width: 1000px;
    // margin-top: 80px;
    left: 50px;
    // height: auto;
    z-index: 1;
    overflow: hidden;
    .control-document-title {
      position: absolute;
      width: 1400px;
      height: 80px;
      text-align: center;
      background-color: #fff;
      top: 430px;
      z-index: 2;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #eee;
    }

    .control-search-input-text {
      left: 26px;
      top: 0px;
      input {
        padding: 12px;
        width: 230px;
        font-size: 14px;
      }
    }

    .control-search-icon {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 250px;
      top: 31px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../icons/search-icon.svg');
    }

    .control-filter {
      position: absolute;
      width: 100px;
      height: 38px;
      left: 290px;
      top: 20px;
      border-radius: 4px;
      border: 1px solid #c4c4c4;
      cursor: pointer;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-indent: 15px;
      font-size: 14px;
    }

    .filter-search-icon {
      position: absolute;
      width: 8px;
      height: 8px;
      left: 82px;
      top: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../icons/black-arrow-icon.svg');
      transform: rotate(90deg);
    }

    .sign-off-all-button {
      position: absolute;
      width: 120px;
      height: 38px;
      right: 20px;
      top: 20px;
      border-radius: 4px;
      border: 1px solid #16b67b;
      background-color: #16b67b;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

    .loading-icon-container {
      position: absolute;
      width: 30px;
      height: 30px;
      left: 50px;
      margin-top: 7px;
      .loading-icon {
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../icons/loading-icon.svg');
        animation: spin 2s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .back-nav {
      position: relative;
      margin-top: 60px;
      margin-bottom: 30px;
      text-indent: 25px;
      color: #818181;
      cursor: pointer;
    }
    .back-nav:hover {
      text-decoration: underline;
    }
    .back-nav::before {
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0px;
      content: '';
      margin-top: 2px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../icons/arrow-icon.svg');
    }
    .header-icon {
      position: absolute;
      width: 42px;
      height: 42px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../icons/shield-icon.svg');
    }
    h4 {
      position: absolute;
      font-weight: 400;
      font-size: 25px;
      margin: 0px;
      margin-top: 6px;
      margin-left: 50px;
      color: #000;
    }
  }

  #home-container {
    // background-color: #000;
    margin: 0 auto;
    margin-top: -5px;
    width: 100%;
    left: 60px;

    h2 {
      position: relative;
      color: #000;
      left: 0px;
      text-align: left;
      height: 20px;
      margin-top: 50px;
    }
  }

  .shift-right {
    left: 320px !important;
  }

  .table-container {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 90%;
    height: auto;
    min-height: 10px;

    .table-title {
      position: relative;
      height: auto;
      font-size: 18px;
      margin: 15px;
      padding-top: 20px;
    }

    .card-holder {
      position: relative;
      width: 450px;
      height: auto;
      margin-bottom: 15px;
    }

    .card-holder-horizontal {
      position: relative;
      height: auto;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 125px;
      width: 1600px;
      .card {
        margin-right: 15px;
        width: 230px;
      }
      .card-number {
        position: relative;
        height: 125px;
        width: 105px;
        background-color: #fff;
        border-radius: 4px;
        display: inline-block;
        @include cardShadow;
        margin-right: 10px;
        span {
          position: absolute;
          width: 100%;
          height: 20px;
          padding-top: 8px;
          text-align: center;
        }
        span.text {
          top: 28px;
          width: 100%;
          height: 20px;
          padding-top: 8px;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
        }
      }
      .card-text {
        position: relative;
        height: 125px;
        width: 225px;
        background-color: #fff;
        border-radius: 4px;
        display: inline-block;
        @include cardShadow;
        margin-right: 10px;
        span {
          position: absolute;
          width: 100%;
          height: 20px;
          padding-top: 8px;
          text-align: center;
        }

        span.text {
          top: 28px;
          width: 100%;
          height: 20px;
          padding-top: 8px;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
        }
      }
        .card-data-element {
        position: relative;
        height: 125px;
        width: 140px;
        background-color: #fff;
        border-radius: 4px;
        display: inline-block;
        @include cardShadow;
        margin-right: 10px;
        span {
          position: absolute;
          width: 100%;
          height: 20px;
          padding-top: 8px;
          text-align: center;
        }
        span.text {
          top: 28px;
          width: 100%;
          height: 20px;
          padding-top: 8px;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .card {
      position: relative;
      height: 100px;
      width: 180px;
      background-color: #fff;
      border-radius: 4px;
      display: inline-block;
      @include cardShadow;
      margin-right: 15px;
      span {
        position: absolute;
        width: 100%;
        height: 20px;
        padding-top: 8px;
        text-align: center;
      }
      span.metric {
        top: 28px;
        font-size: 42px;
      }
      span.text {
        top: 28px;
        width: 100%;
        height: 20px;
        padding-top: 8px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
      }
    }
   .chart-right{
      position: relative;
      height: 125px;
      width: 500px;
      background-color: #fff;
      border-radius: 4px;
      display: inline-block;
      @include cardShadow;
      margin-right: 10px;
    }
    .chart-holder {
      position: absolute;
      left: 400px;
      top: 0px;
      height: 220px;
      width: 485px;
      background-color: #fff;
      border-radius: 4px;
      .recharts-pie-sector {
        cursor: pointer;
      }
      @include cardShadow;
      .hover-info {
        position: absolute;
        width: 100px;
        height: auto;
        z-index: 3;
        left: 95px;
        top: 90px;
        text-align: center;
        span {
          position: relative;
          width: 100%;
          font-weight: 500;
        }
        .hover-title {
          text-transform: uppercase;
          font-size: 14px;
          border: none !important;
        }
        .hover-metric {
          font-size: 20px;
          border: none !important;
        }
      }
      .passed {
        color: #16b67b;
      }
      .failed {
        color: #e0123c;
      }
      .underway {
        color: #ffc73a;
      }
      .scheduled {
        color: #b2b2b2;
      }
      .chart-key {
        position: absolute;
        width: 155px;
        height: auto;
        top: 52px;
        right: 25px;
        z-index: 2;
        .key-item {
          position: relative;
          width: 100%;
          height: 20px;
          margin-bottom: 12px;
          .box {
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: grey;
            left: 0px;
            border-radius: 4px;
          }
          span {
            margin-left: 30px;
            font-size: 15px;
          }
        }
      }
    }
  }

  .popup-container {
    position: fixed;
    width: 100%;
    height: auto;
    min-height: 100%;
    top: 0px;
    z-index: 3000;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    .popup-modal {
      position: absolute;
      width: 550px;
      height: 800px;
      background-color: #f4f5f7;
      @include cardShadow;
      border-radius: 2px;
      z-index: 2;
      .popup-modal-header {
        position: relative;
        top: 0px;
        width: 100%;
        height: 115px;
        background-color: #fff;
        border-radius: 2px 2px 0px 0px;
        .header-title {
          position: relative;
          left: 30px;
          top: 45px;
          z-index: 1;
          font-size: 18px;
          font-weight: 500;
        }
        .close-popup {
          position: absolute;
          width: 30px;
          height: 30px;
          left: 3px;
          top: 3px;
          cursor: pointer;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 40%;
          background-image: url('../icons/close-icon.svg');
          z-index: 2;
        }
        .rule-status-menu {
          position: absolute;
          width: 100px;
          height: 25px;
          background-color: #eee;
          top: 73px;
          left: 30px;
          border-radius: 4px;
          color: #ffc73a;
          border: 1px solid #ffc73a;
          background-color: #fff8e8;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .menu-options {
            position: absolute;
            width: 120px;
            height: auto;
            background-color: #fff;
            z-index: 3;
            top: 26px;
            left: -2px;
            border-radius: 2px;
            border: 1px solid #eee;
            @include cardShadow();
            display: none;
            .option {
              position: relative;
              width: 105px;
              height: 30px;
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 15px;
              color: #000;
            }
            .option:hover {
              background-color: #eee;
              color: #000;
            }
          }
        }
        .rule-status-menu:hover {
          .menu-options {
            display: block;
          }
        }
        .validation-status {
          position: absolute;
          width: 100px;
          height: 25px;
          background-color: #eee;
          top: 8px;
          right: 8px;
          border-radius: 4px;
          color: #ffc73a;
          border: 1px solid #ffc73a;
          background-color: #fff8e8;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .passed {
          color: #16b67b;
          border: 1px solid #16b67b;
          background-color: #eaf9f3;
        }
        .failed {
          color: #e0123c;
          border: 1px solid #e0123c;
          background-color: #fdf2f4;
        }
        .underway,
        .scheduled,
        .investigate {
          color: #ffc73a;
          border: 1px solid #ffc73a;
          background-color: #fff8e8;
        }

        .validation-status-details {
          position: absolute;
          color: #000;
          text-align: right;
          right: 118px;
          width: 200px;
          font-size: 10px;
          font-weight: 400;
          top: 16px;
        }
      }
      .popup-modal-content {
        position: relative;
        margin-top: 0px;
        padding-left: 30px;
        height: 685px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        overflow: scroll;
        overflow-x: hidden;
        .title {
          position: relative;
          margin-top: 70px;
          font-weight: 500;
          width: 488px;
          margin-bottom: 15px;
          .last-updated {
            position: absolute;
            left: 0px;
            font-size: 12px;
            text-align: right;
            font-weight: 400;
            top: 22px;
            color: #595959;
          }
        }
        p {
          font-size: 12px;
          max-width: 480px;
          color: #595959;
          margin-top: -5px;
          padding-top: 0px;
        }
        .input-text {
          margin-top: 10px;
          background-color: #fff;
          width: 490px;
        }
        .rule-popup-button {
          position: relative;
          top: 35px;
          left: 420px;
          margin-bottom: 80px;
        }
        .saving-button {
          opacity: 0.5;
          left: 405px;
        }
      }
    }
    .disable-modal {
      pointer-events: none;
    }
    .popup-background {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.2;
      z-index: 1;
    }
  }
}

// REFACTORED APP STYLES

.closed-draw-align {
  section {
    margin-left: 0px;
    transition: margin-left 0.3s;
  }
  .section-container {
    // margin-left: 130px;
    width: 90%;
  }
  .compliance-container {
    // margin-left: -150px;
  }
}

.section-container {
  position: relative;
  width: 80%;
  // margin: 0 auto;
  // min-width: 900px;
  margin-top: -100px;
  margin-left: 60px;
  width: 95%;
  top: -45px;
  @media only screen and (max-width: 1750px) {
    // margin-left: 280px;
    // width: 80%;
  }
  .owner-text {
    position: relative;
    margin-top: 90px !important;
    margin-bottom: 0px;
  }
}

.back-nav {
  position: relative;
  margin-top: 160px;
  margin-bottom: 30px;
  text-indent: 25px;
  color: #818181;
  cursor: pointer;
}
.back-nav:hover {
  text-decoration: underline;
}
.back-nav::before {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0px;
  content: '';
  margin-top: 2px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../icons/arrow-icon.svg');
}
.header-icon {
  position: absolute;
  width: 42px;
  height: 42px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../icons/shield-icon.svg');
}
h4 {
  position: absolute;
  font-weight: 400;
  font-size: 25px;
  margin: 0px;
  margin-top: 6px;
  margin-left: 50px;
  color: #000;
}
.compliance-category-nav {
  margin-top: -80px !important;
}
